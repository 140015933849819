const thai = {
  errmsg: 'มีปัญหาเกิดขึ้นแล้ว',
  up: 'ที่อยู่ผู้บังคับบัญชา:',
  bindup: 'ผูกผู้บังคับบัญชา',
  confirm: 'ยืนยัน',
  toast10: 'เปิดตัวรอการยืนยันบล็อก',
  toast13: 'การรับที่อยู่ล้มเหลว',
  total: 'สมัครสมาชิกทั้งหมด',
  total1: 'ยอดคงเหลือ EGA',
  total2: 'รางวัลแบบไดนามิก',
  withraw: 'การสกัด',
  team: 'ประสิทธิภาพของทีม',
  team1: 'ทีม',
  team2: 'คน',
  team3: 'กดตรง',
  one: 'ครั้งเดียว',
  onetime: 'ระยะเวลา',
  oneprice: 'วงเงิน',
  day: 'วัน',
  buy: 'สมัครสมาชิก',
  sheng: 'ส่วนที่เหลือ',
  income: 'ผลประโยชน์ที่คาดหวัง',
  title: 'โฮสติ้ง',
  time: 'ระยะเวลา',
  close: 'ปิดก่อนกำหนด',
  timedown: 'นับถอยหลัง',
  daoqi: 'หมดอายุ',
  buyed: 'สมัครสมาชิกแล้ว',
  incomed: 'รายได้',
  nodata: 'POOL ที่ยังไม่ได้โฮสต์ในขณะนี้',
  tiyan: 'ประสบการณ์',
  oneNum: 'จำกัด การสมัครสมาชิก',
  allIncome: 'รายได้รวม',
  balance: 'สินทรัพย์ที่มีอยู่',
  submit: 'ยืนยันการสมัครสมาชิก',
  poolAll: 'POOL ทั้งหมด',
  shengyu: 'เหลือให้ซื้อ',
  buynum: 'จำนวนสมาชิก',
  input: 'ใส่จำนวนเงิน',
  max: 'แม็กซ์',
  title1: 'TianPOOL - ถอนเงิน',
  can: 'สกัดได้',
  sure: 'ยืนยันการถอนเงิน',
  title2: 'ประสบการณ์ POOL - ถอนกำไร',
  tab1: 'ในโฮสติ้ง',
  tab2: 'จบแล้ว',
  tian: 'วัน',
  shi: 'ชั่วโมง',
  fen: 'นาที',
  miao: 'วินาที',
  alert1: 'ปิด',
  alert2: 'ยืนยันจะปิดก่อนกำหนด',
  alert3: 'กรุณากรอกที่อยู่',
  alert4: 'กรุณาใส่ปริมาณ',
  alert5: 'ยอดคงเหลือไม่เพียงพอ',
  alert6: 'จำนวนเงินลงทุนขั้นต่ำ',
  end: 'สิ้นสุด',
  tabbar1: 'สินทรัพย์',
  tabbar2: 'พาโนรามา',
  tabbar3: 'จิ๋ว',
  noopen: 'ยังไม่เปิดให้บริการในขณะนี้ โปรดติดตาม',
  ketiqu: 'กำไรที่ถอนได้',
  jingtai: 'กำไรคงที่',
  jiedian: 'กำไรโหนด',
  futou: 'ยืนยันการลงคะแนนซ้ำ',
  shifang: 'ปล่อยแล้ว',
}
export default thai;
