const chinese1 = {
  errmsg: '出現問題啦～',
  up: '上級地址：',
  bindup: '綁定上級',
  confirm: '確認',
  toast10: '已發起，等待區塊確認',
  toast13: '獲取地址失敗',
  total: '總認購',
  total1: 'EGA餘額',
  total2: '動態獎勵',
  withraw: '提取',
  team: '團隊業績',
  team1: '團隊',
  team2: '人',
  team3: '直推',
  one: '僅限購一次',
  onetime: '週期',
  oneprice: '限額',
  day: '天',
  buy: '認購',
  sheng: '剩餘',
  income: '預計收益',
  title: '託管',
  time: '期限',
  close: '提前關閉',
  timedown: '倒數計時',
  daoqi: '到期',
  buyed: '已認購',
  incomed: '已收益',
  nodata: '暫時還沒有託管的POOL',
  tiyan: '體驗',
  oneNum: '限額認購',
  allIncome: '總收益',
  balance: '可用資產',
  submit: '確認認購',
  poolAll: 'POOL總量',
  shengyu: '剩餘可購',
  buynum: '認購金額',
  input: '輸入金額',
  max: '最大',
  title1: '天POOL-選取收益',
  can: '可選取',
  sure: '確認選取',
  title2: '體驗POOL-選取收益',
  tab1: '託管中',
  tab2: '已結束',
  tian: '天',
  shi: '時',
  fen: '分',
  miao: '秒',
  alert1: '關閉',
  alert2: '確認提前關閉嗎',
  alert3: '請輸入地址',
  alert4: '請輸入數量',
  alert5: '餘額不足',
  alert6: '最低投資金額',
  end: '結束',
  tabbar1: '資產包理財',
  tabbar2: '全景',
  tabbar3: '智投',
  noopen: '暫未開放敬請期待',
  ketiqu: '可選取收益',
  jingtai: '靜態收益',
  jiedian: '節點收益',
  futou: '確認複投',
  shifang: '已釋放',
}
export default chinese1;
