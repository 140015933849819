const chinese = {
  errmsg: '出现问题啦～',
  up: '上级地址：',
  bindup: '绑定上级',
  confirm: '确认',
  toast10: '已发起，等待区块确认',
  toast13: '获取地址失败',
  total: '总认购',
  total1: 'EGA余额',
  total2: '动态奖励',
  withraw: '提取',
  team: '团队业绩',
  team1: '团队',
  team2: '人',
  team3: '直推',
  one: '仅限购一次',
  onetime: '周期',
  oneprice: '限额',
  day: '天',
  buy: '认购',
  sheng: '剩余',
  income: '预计收益',
  title: '托管 ',
  time: '期限',
  close: '提前关闭',
  timedown: '倒计时',
  daoqi: '到期',
  buyed: '已认购',
  incomed: '已收益',
  nodata: '暂时还没有托管的POOL',
  tiyan: '体验 ',
  oneNum: '限额认购',
  allIncome: '总收益',
  balance: '可用资产',
  submit: '确认认购',
  poolAll: 'POOL总量',
  shengyu: '剩余可购',
  buynum: '认购金额',
  input: '输入金额',
  max: '最大',
  title1: '天POOL-提取收益',
  can: '可提取',
  sure: '确认提取',
  title2: '体验POOL-提取收益',
  tab1: '托管中',
  tab2: '已结束',
  tian: '天',
  shi: '时',
  fen: '分',
  miao: '秒',
  alert1: '关闭',
  alert2: '确认提前关闭吗',
  alert3: '请输入地址',
  alert4: '请输入数量',
  alert5: '余额不足',
  alert6: '最低投资金额',
  end: '结束',
  tabbar1: '资产包理财',
  tabbar2: '全景',
  tabbar3: '智投',
  noopen: '暂未开放 敬请期待',
  ketiqu: '可提取收益',
  jingtai: '静态收益',
  jiedian: '节点收益',
  futou: '确认复投',
  shifang: '已释放',
}
export default chinese;
